export function getMilliSecond(timeStr) {
  let unit = timeStr.slice(-1);
  let value = parseInt(timeStr);

  switch (unit) {
    case "m":
      return value * 60 * 1000;

    case "h":
      return value * 60 * 60 * 1000;

    case "d":
      return value * 60 * 60 * 24 * 1000;
  }
}

export function getStep(time, step) {
  return parseInt(Number(time) / (step * 1000), 10);
}

export function mapPodStatus(status) {
  if (["Running", "ContainerCreating"].includes(status)) return "success";
  if (["Completed"].includes(status)) return "completed";
  if (["Pending"].includes(status)) return "danger";

  return "error";
}

export function formatAxisLabel(size) {
  if (!size) return "";

  let num = 1000; //byte
  if (size < num) return Number(size).toFixed(1);
  if (size < Math.pow(num, 2)) return (size / num).toFixed(1) + "K"; //kb
  if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(1) + "M"; //M
  if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(1) + "G"; //G
  return (size / Math.pow(num, 4)).toFixed(1) + "T"; //T
}
