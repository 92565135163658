<template>
  <div>
    <splitpanes class="default-theme panels-container" horizontal>
      <pane>
        <el-table
          stripe
          :data="data"
          @select="selectRow"
          @selection-change="selectionChange"
          @row-click="rowClick"
          @select-all="selectAll"
          row-key="metadata.uid"
          ref="applicationTable"
        >
          <el-table-column type="selection" width="50" :reserve-selection="true"> </el-table-column>

          <el-table-column :label="$t('name')" prop="metadata.name" sortable width="200">
            <template slot-scope="scope">
              <div class="table-name-info">
                <svg-icon :icon-class="$route.params.type"></svg-icon>
                <div class="content">
                  <a @click.stop="toDetail(scope.row)">
                    {{ scope.row.metadata.name }}
                  </a>

                  <span class="desc">
                    {{ moment(scope.row.spec.age).fromNow() }}
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('Namespace')" prop="metadata.namespace" width="160">
            <template slot-scope="scope">
              <i class="el-icon-document-copy" v-clipboard:copy="scope.row.metadata.namespace" @click.stop></i>
              {{ scope.row.metadata.namespace }}
            </template>
          </el-table-column>

          <el-table-column :label="$t('schedule')" v-if="type === 'CronJob'" width="100">
            <template slot-scope="scope">
              <div class="title" style="font-size: 14px;">{{ scope.row.spec.cronJob.schedule }}</div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('status')" v-if="type === 'CronJob'" width="100">
            <template slot-scope="scope">
              <div class="status danger" v-if="scope.row.spec.cronJob.suspend">Suspended</div>
              <div class="status success" v-else>Scheduled</div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('lastSchedule') + '(GMT +08:00)'" v-if="type === 'CronJob'" width="200">
            <template slot-scope="scope">
              <div>
                {{ moment(scope.row.spec.cronJob.lastSchedule).format("YYYY-MM-DD HH:mm:ss") }}
              </div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('nextSchedule') + '(GMT +08:00)'" v-if="type === 'CronJob'" width="200">
            <template slot-scope="scope">
              <div>
                {{ moment(scope.row.spec.cronJob.next).format("YYYY-MM-DD HH:mm:ss") }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('applicationReady')"
            prop="spec.deployment.ready"
            v-if="type === 'Deployment'"
            width="50"
          >
            <template slot-scope="scope">
              <div v-html="mapReadyHtml(scope.row.spec.deployment.ready)"></div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('activeCronJob')" prop="spec.cronJob.active" v-if="type === 'CronJob'">
          </el-table-column>

          <el-table-column
            :label="$t('daemonSetCurrent')"
            prop="spec.daemonSet.current"
            v-if="type === 'DaemonSet'"
            width="100"
          >
          </el-table-column>

          <el-table-column
            :label="$t('daemonSetDesired')"
            prop="spec.daemonSet.desired"
            v-if="type === 'DaemonSet'"
            width="100"
          >
          </el-table-column>

          <el-table-column label="QoS" prop="spec.qos" width="80"> </el-table-column>

          <el-table-column :label="$t('listCpuUsage')" width="120">
            <template slot-scope="scope">
              <resource-percent type="cpu" :resources="scope.row.spec.resources" />
            </template>
          </el-table-column>

          <el-table-column :label="$t('listMemoryUsage')" width="120">
            <template slot-scope="scope">
              <resource-percent type="memory" :resources="scope.row.spec.resources" />
            </template>
          </el-table-column>

          <el-table-column :label="$t('container')" min-width="300">
            <template slot-scope="scope">
              <div v-for="(container, index) in scope.row.spec.containers" :key="container">
                <i class="el-icon-document-copy" v-clipboard:copy="scope.row.spec.images[index]" @click.stop></i>
                {{ scope.row.spec.images[index] || "-" }}
              </div>
            </template>
          </el-table-column>

          <el-table-column :label="$t('Cluster')" width="260">
            <template slot-scope="scope">
              {{ JSON.parse(scope.row.metadata.annotations.providerTopology).cluster }}
            </template>
          </el-table-column>

          <!-- <el-table-column :label="$t('region')">
            <template slot-scope="scope">
              {{ JSON.parse(scope.row.metadata.annotations.providerTopology).region }}
            </template>
          </el-table-column> -->

          <el-table-column :label="$t('handle.handle')" width="100">
            <template slot-scope="scope">
              <el-dropdown trigger="click">
                <button class="table-row-handle" @click.stop>
                  <i class="el-icon-more-outline"></i>
                </button>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <update
                      :vendor="JSON.parse(scope.row.metadata.annotations.providerTopology).vendor"
                      :region="JSON.parse(scope.row.metadata.annotations.providerTopology).region"
                      :cluster="JSON.parse(scope.row.metadata.annotations.providerTopology).cluster"
                      :application="scope.row.metadata.name"
                      :namespace="scope.row.metadata.namespace"
                      v-on="$listeners"
                      v-if="['Deployment', 'StatefulSet', 'DaemonSet'].includes(type)"
                    />
                  </el-dropdown-item>

                  <el-dropdown-item>
                    <rollback
                      :vendor="JSON.parse(scope.row.metadata.annotations.providerTopology).vendor"
                      :region="JSON.parse(scope.row.metadata.annotations.providerTopology).region"
                      :cluster="JSON.parse(scope.row.metadata.annotations.providerTopology).cluster"
                      :application="scope.row.metadata.name"
                      :namespace="scope.row.metadata.namespace"
                      v-on="$listeners"
                      v-if="['Deployment'].includes(type)"
                    />
                  </el-dropdown-item>

                  <el-dropdown-item>
                    <scale
                      :vendor="JSON.parse(scope.row.metadata.annotations.providerTopology).vendor"
                      :region="JSON.parse(scope.row.metadata.annotations.providerTopology).region"
                      :cluster="JSON.parse(scope.row.metadata.annotations.providerTopology).cluster"
                      :application="scope.row.metadata.name"
                      :namespace="scope.row.metadata.namespace"
                      v-on="$listeners"
                      v-if="['Deployment', 'StatefulSet'].includes(type)"
                    />
                  </el-dropdown-item>

                  <el-dropdown-item>
                    <auto-scale
                      :vendor="JSON.parse(scope.row.metadata.annotations.providerTopology).vendor"
                      :region="JSON.parse(scope.row.metadata.annotations.providerTopology).region"
                      :cluster="JSON.parse(scope.row.metadata.annotations.providerTopology).cluster"
                      :application="scope.row.metadata.name"
                      :namespace="scope.row.metadata.namespace"
                      v-on="$listeners"
                      v-if="['Deployment'].includes(type)"
                    />
                  </el-dropdown-item>

                  <el-dropdown-item>
                    <pause
                      :vendor="JSON.parse(scope.row.metadata.annotations.providerTopology).vendor"
                      :region="JSON.parse(scope.row.metadata.annotations.providerTopology).region"
                      :cluster="JSON.parse(scope.row.metadata.annotations.providerTopology).cluster"
                      :application="scope.row.metadata.name"
                      :namespace="scope.row.metadata.namespace"
                      :pause="scope.row.spec.cronJob.suspend"
                      v-on="$listeners"
                      v-if="['CronJob'].includes(type)"
                    />
                  </el-dropdown-item>

                  <el-dropdown-item>
                    <delete
                      :vendor="JSON.parse(scope.row.metadata.annotations.providerTopology).vendor"
                      :region="JSON.parse(scope.row.metadata.annotations.providerTopology).region"
                      :cluster="JSON.parse(scope.row.metadata.annotations.providerTopology).cluster"
                      :name="scope.row.metadata.name"
                      :namespace="scope.row.metadata.namespace"
                      :type="type"
                      v-on="$listeners"
                    />
                  </el-dropdown-item>

                  <el-dropdown-item>
                    <div @click="toKubeStarCtlInfo(scope.row)">KubeStarCtl信息</div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </pane>

      <pane size="40">
        <div class="table-pane-container">
          <div class="desc" v-if="selection.length < 1">
            {{ $t("listSelectResourceTips") }}
          </div>

          <div v-if="selection.length > 0">
            <div class="batch-handle">
              <el-button
                size="small"
                type="primary"
                v-if="['Deployment'].includes(type)"
                plain
                @click="batchHandle('Scale')"
              >
                {{ $t("handle.batchScaleByHand") }}
              </el-button>
              <el-button
                size="small"
                type="primary"
                v-if="['Deployment'].includes(type)"
                plain
                @click="batchHandle('HPA')"
              >
                {{ $t("handle.batchScaleAuto") }}
              </el-button>
              <el-button size="small" type="danger" @click="batchHandle('Delete')" plain>
                {{ $t("handle.batchDelete") }}
              </el-button>
            </div>

            <el-tabs v-model="applicationTab" closable type="card" @tab-remove="removeTab" @tab-click="tabClick">
              <el-tab-pane
                v-for="item in selection"
                :key="item.metadata.uid"
                :label="item.metadata.name"
                :name="item.metadata.uid"
              >
                <div v-if="loading">
                  <content-placeholders>
                    <content-placeholders-text :lines="4" />
                  </content-placeholders>
                </div>

                <div class="pane-detail-tab" v-if="!loading && item.metadata.uid === applicationTab">
                  <el-tabs>
                    <el-tab-pane :label="$t('basicConf')">
                      <el-row :gutter="20" style="margin-bottom: 20px;">
                        <el-col :span="18">
                          <pod
                            type="application"
                            :vendor="vendor"
                            :region="region"
                            :cluster="cluster"
                            :namespace="namespace"
                            :name="application"
                          />
                        </el-col>

                        <el-col :span="6">
                          <div class="name-value" v-for="(item, index) in info" :key="index">
                            <span class="name">{{ item.name }}：</span>
                            <span class="value">
                              <i class="el-icon-document-copy" v-clipboard:copy="item.value"></i>
                              {{ item.value }}
                            </span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </pane>
    </splitpanes>

    <el-dialog :title="$t('handle.batchHandle')" :visible.sync="dialogVisible" top="30px" :close-on-click-modal="false">
      <a-steps v-model="batchStep" type="navigation" size="small" :style="stepStyle" :initial="1">
        <a-step title="Step 1" :description="$t('applicationListBatchStep.1')" disabled />
        <a-step title="Step 2" :description="$t('applicationListBatchStep.2')" disabled />
      </a-steps>

      <div v-if="batchStep === 1">
        <div class="selected-title">{{ $t("listHasSelectedApplications") }}：</div>
        <ul class="selected-list">
          <li v-for="item in selection" :key="item.metadata.uid">
            {{ item.metadata.name }}
          </li>
        </ul>
      </div>

      <div v-if="batchStep === 2">
        <el-form :model="form" size="small" label-position="top" v-if="batchType == 'Scale'">
          <el-form-item :label="$t('replicas')" prop="slider">
            <el-input v-model.number="form.slider" :min="0" oninput="value=value.replace(/[^0-9.]/g,'')">
              <template slot="prepend">{{ $t("replicas") }}</template>
            </el-input>
          </el-form-item>
        </el-form>

        <el-form :model="autoScaleForm" size="small" label-position="top" v-if="batchType == 'HPA'">
          <el-form-item
            :label="$t('autoScaleResource')"
            prop="resource"
            :rules="{ required: true, message: $t('rules.require'), trigger: 'change' }"
          >
            <el-select v-model="autoScaleForm.resource" multiple>
              <el-option :label="$t('cpu')" value="cpu"></el-option>
              <el-option :label="$t('memory')" value="memory"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            :label="$t('cpuThreshold')"
            v-if="autoScaleForm.resource.includes('cpu')"
            prop="cpuThreshold"
            :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
          >
            <el-input v-model="autoScaleForm.cpuThreshold">
              <el-select v-model="autoScaleForm.cpuUnit" slot="append" class="input-with-select">
                <el-option label="%" value="percent"></el-option>
                <el-option label="m" value="m"></el-option>
                <el-option label="core" value="core"></el-option>
              </el-select>
            </el-input>
          </el-form-item>

          <el-form-item
            :label="$t('memoryThreshold')"
            v-if="autoScaleForm.resource.includes('memory')"
            prop="memoryThreshold"
            :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
          >
            <el-input v-model="autoScaleForm.memoryThreshold">
              <el-select v-model="autoScaleForm.memoryUnit" slot="append" class="input-with-select">
                <el-option label="%" value="percent"></el-option>
                <el-option label="GiB" value="GiB"></el-option>
                <el-option label="MiB" value="MiB"></el-option>
              </el-select>
            </el-input>
          </el-form-item>

          <el-form-item :label="$t('minReplicas')">
            <el-input v-model.number="autoScaleForm.minReplicas" :min="0" oninput="value=value.replace(/[^0-9.]/g,'')">
              <template slot="prepend">
                {{ $t("replicas") }}
              </template>
            </el-input>
          </el-form-item>

          <el-form-item :label="$t('maxReplicas')">
            <el-input v-model.number="autoScaleForm.maxReplicas" :min="0" oninput="value=value.replace(/[^0-9.]/g,'')">
              <template slot="prepend">
                {{ $t("replicas") }}
              </template>
            </el-input>
          </el-form-item>
        </el-form>

        <div v-if="batchType == 'Delete'">
          <el-alert :title="$t('batchDelete')" effect="dark" type="error" style="margin-bottom: 10px;"> </el-alert>

          <ul class="selected-list">
            <li v-for="item in selection" :key="item.metadata.uid">
              {{ item.metadata.name }}
            </li>
          </ul>
        </div>
      </div>

      <span slot="footer">
        <el-button type="primary" size="small" @click="batchStep++" v-if="batchStep === 1">
          {{ $t("handle.nextStep") }}
        </el-button>

        <el-button type="primary" size="small" @click="batchStep--" v-if="batchStep > 1">
          {{ $t("handle.lastStep") }}
        </el-button>

        <el-button type="primary" size="small" @click="submit" :loading="batchLoading" v-if="batchStep > 1">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>

    <el-dialog title="KubeStarCtl信息" :visible.sync="kubestarCtlVisible" top="30px">
      <yaml-container :text="kubeStarCtlInfo" :transfer="false" />
    </el-dialog>
  </div>
</template>

<script>
import toDetail from "./toDetail";
import { deploymentDetail, applicationBatchHandle } from "api/deployment";
import { Splitpanes, Pane } from "splitpanes";
import Update from "@/pages/Application/Update";
import Rollback from "@/pages/Application/Rollback";
import Scale from "@/pages/Application/Scale";
import AutoScale from "@/pages/Application/AutoScale";
import Pause from "@/pages/Application/Pause";
import Delete from "@/pages/Delete/index";
import Pod from "@/pages/Detail/components/Pod";
import ResourcePercent from "@/components/ResourcePercent";
import { startsWith } from "lodash";

import { mapInfo } from "utils/info.js";
const YAML = require("json2yaml");

export default {
  props: {
    data: Array,
    toDetail: Function
  },

  components: {
    Splitpanes,
    Pane,
    Update,
    Rollback,
    Scale,
    AutoScale,
    Pause,
    Delete,
    Pod,
    ResourcePercent
  },

  mixins: [toDetail],

  data() {
    return {
      selection: [],
      loading: false,
      info: [],

      applicationTab: null,
      vendor: "",
      region: "",
      cluster: "",
      namespace: "",
      application: "",
      batchType: "",
      dialogVisible: false,

      form: {
        slider: 3
      },

      autoScaleForm: {
        resource: [],
        minReplicas: 1,
        maxReplicas: 10,
        cpuUnit: "percent",
        cpuThreshold: "60",
        memoryThreshold: "60",
        memoryUnit: "percent"
      },

      batchLoading: false,
      batchStep: 1,

      stepStyle: {
        marginBottom: "10px",
        boxShadow: "0px -1px 0 0 #e8e8e8 inset"
      },

      kubestarCtlVisible: false,
      kubeStarCtlInfo: {}
    };
  },

  methods: {
    mapReadyHtml(ready) {
      let [readyCount, totalCount] = ready.split("/");

      let html = `
        <div>
          <span style="font-size: 12px; color: ${
            readyCount === totalCount ? "#67c23a" : "#ff9f00"
          }; font-weight: bolder;">
            ${readyCount}
          </span>
          /
          ${totalCount}
        </div>
      `;

      return html;
    },

    selectionChange(selection) {
      this.selection = selection;
    },

    tabClick() {
      for (let item of this.selection) {
        if (item.metadata.uid === this.applicationTab) {
          this.selectRow(this.selection, item);
          return;
        }
      }
    },

    removeTab(targetName) {
      this.selection.forEach((item, index, arr) => {
        if (item.metadata.uid === targetName) {
          arr.splice(index, 1);
          //取消列表复选框
          this.$refs.applicationTable.toggleRowSelection(item);
          if (this.selection.length > 0) this.selectRow(this.selection, arr[arr.length - 1]);
        }
      });
    },

    rowClick(row) {
      this.$refs.applicationTable.toggleRowSelection(row);
      this.selectRow(this.selection, row);
    },

    selectAll() {
      if (this.selection.length > 0) this.selectRow(this.selection, this.selection[0]);
    },

    selectRow(selection, row) {
      console.log(selection);
      console.log(row);

      if (selection.length > 0) {
        // row-key?
        let selected = selection.indexOf(row) !== -1;

        if (selected) {
          this.applicationTab = row.metadata.uid;
          this.application = row.metadata.name;
          this.namespace = row.metadata.namespace;

          this.vendor = JSON.parse(row.metadata.annotations.providerTopology).vendor;
          this.region = JSON.parse(row.metadata.annotations.providerTopology).region;
          this.cluster = JSON.parse(row.metadata.annotations.providerTopology).cluster;
        } else if (selection.length > 0) {
          this.applicationTab = selection[0].metadata.uid;

          this.application = selection[0].metadata.name;
          this.namespace = selection[0].metadata.namespace;
          this.vendor = JSON.parse(selection[0].metadata.annotations.providerTopology).vendor;
          this.region = JSON.parse(selection[0].metadata.annotations.providerTopology).region;
          this.cluster = JSON.parse(selection[0].metadata.annotations.providerTopology).cluster;
        }

        this.loading = true;
        deploymentDetail(
          {
            application: this.application,
            ...{ vendor: this.vendor, region: this.region, cluster: this.cluster, namespace: this.namespace }
          },
          { type: this.$route.params.type }
        ).then(response => {
          this.loading = false;

          if (response.code === 0) {
            this.info = mapInfo(this.$route.params.type, response.data);
          }
        });
      }
    },

    batchHandle(type) {
      this.batchType = type;
      this.dialogVisible = true;
      this.batchStep = 1;
    },

    submit() {
      let data = {
        operation: this.batchType,
        applications: this.selection,
        replicas: 3,
        hpaSet: {
          spec: {
            maxReplicas: 8,
            minReplicas: 1,
            metrics: []
          }
        }
      };

      if (this.batchType === "Scale") {
        data.replicas = this.form.slider;
      }

      if (this.batchType === "HPA") {
        data.hpaSet.spec.metrics = [];

        this.autoScaleForm.resource.forEach(item => {
          let target = {};
          if (item === "cpu") {
            if (this.autoScaleForm.cpuUnit == "percent") {
              target = {
                type: "Utilization",
                averageUtilization: Number(this.autoScaleForm.cpuThreshold)
              };
            }

            if (this.autoScaleForm.cpuUnit == "m") {
              target = {
                type: "Value",
                value: `${this.autoScaleForm.cpuThreshold}m`
              };
            }

            if (this.autoScaleForm.cpuUnit == "core") {
              target = {
                type: "Value",
                value: this.autoScaleForm.cpuThreshold
              };
            }
          }

          if (item === "memory") {
            if (this.autoScaleForm.memoryUnit == "percent") {
              target = {
                type: "Utilization",
                averageUtilization: Number(this.autoScaleForm.memoryThreshold)
              };
            }

            if (this.autoScaleForm.memoryUnit == "MiB") {
              target = {
                type: "Value",
                value: `${this.autoScaleForm.memoryThreshold}M`
              };
            }

            if (this.autoScaleForm.memoryUnit == "GiB") {
              target = {
                type: "Value",
                value: `${this.autoScaleForm.memoryThreshold}G`
              };
            }
          }

          data.hpaSet.spec.metrics.push({
            type: "Resource",
            resource: {
              name: item,
              target
            }
          });

          data.hpaSet.spec.minReplicas = this.autoScaleForm.minReplicas;
          data.hpaSet.spec.maxReplicas = this.autoScaleForm.maxReplicas;
        });
      }

      this.batchLoading = true;
      applicationBatchHandle(data, { type: this.type }).then(response => {
        this.batchLoading = false;
        if (response.code === 0) {
          this.dialogVisible = false;

          if (this.batchType == "Delete") {
            this.$emit("delete");
          } else {
            // 在不是删除的操作下，重新获取资源详情
            this.$emit("update");
            this.loading = true;
            this.$nextTick(() => {
              this.loading = false;
            });
          }
        }
      });
    },

    toKubeStarCtlInfo(row) {
      this.kubestarCtlVisible = true;
      console.log(row);

      let containerInfo = [];

      row.spec.containers.forEach((item, index) => {
        containerInfo.push({
          name: item,
          image:
            row.spec.images[index].substring(0, row.spec.images[index].lastIndexOf(":") + 1) + `\${VERSION${index + 1}}`
        });
      });

      let kubeStarCtlInfo = YAML.stringify({
        vendor: JSON.parse(row.metadata.annotations.providerTopology).vendor,
        region: JSON.parse(row.metadata.annotations.providerTopology).region,
        "cluster-name": JSON.parse(row.metadata.annotations.providerTopology).cluster,
        "resource-type": this.type,
        namespace: row.metadata.namespace,
        "resource-name": row.metadata.name,
        containers: containerInfo
      });

      console.log(kubeStarCtlInfo);

      if (startsWith(kubeStarCtlInfo, "---")) kubeStarCtlInfo = kubeStarCtlInfo.substring(3);
      this.kubeStarCtlInfo = "#!kubestar\n" + kubeStarCtlInfo;
    }
  },

  computed: {
    type() {
      return this.$route.params.type;
    }
  }
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
.container-name {
  @include flex(flex-start, center, nowrap);

  a {
    margin-right: 8px;
  }

  .svg-icon.handle-icon {
    width: 14px;
    height: 14px;
  }
}
.panels-container {
  height: calc(100vh - 210px);

  .splitpanes__pane {
    overflow-y: scroll;
  }
}

.splitpanes.default-theme .splitpanes__pane {
  background-color: transparent;
}

.splitpanes.default-theme .splitpanes__splitter {
  box-shadow: 0px -4px 8px 0px rgba(175, 180, 184, 0.5);
}

.table-pane-container {
  padding: 10px 0;
}

.pane-detail-tab {
  & > .el-tabs {
    padding: 0 2 * $grid-height;
    border-radius: 0 0 $radius-width $radius-width;
    border: none;

    .el-tabs__nav-wrap::after {
      display: none;
    }
  }
}

.batch-handle {
  margin-bottom: 10px;
}

.selected-title {
  @include title(14px);
  margin-bottom: 10px;
}

.selected-list {
  li {
    position: relative;
    text-indent: 12px;
    color: $color-sub;
    margin-bottom: 4px;

    &::after {
      position: absolute;
      width: 6px;
      height: 6px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      background-color: #257adf;
    }
  }
}
</style>
