export default {
  methods: {
    toNamespace(row) {
      let { namespace } = row.metadata;
      let { vendor, region, cluster } = JSON.parse(row.metadata.annotations.providerTopology);

      let query = { vendor: vendor, region: region, cluster: cluster };

      this.$router.push({
        path: `/detail/Namespace/${namespace}`,
        query
      });
    }
  }
};
