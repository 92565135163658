<template>
  <el-table stripe :data="data">
    <el-table-column :label="$t('name')" prop="metadata.name" sortable width="200">
      <template slot-scope="scope">
        <div class="table-name-info">
          <svg-icon :icon-class="$route.params.type"></svg-icon>
          <div class="content">
            <a @click="toDetail(scope.row)">
              {{ scope.row.metadata.name }}
            </a>

            <span class="desc">
              {{ moment(scope.row.spec.age).fromNow() }}
            </span>
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('Namespace')" prop="metadata.namespace" width="140">
      <template slot-scope="scope">
        <i class="el-icon-document-copy" v-clipboard:copy="scope.row.metadata.namespace" @click.stop></i>
        {{ scope.row.metadata.namespace }}
      </template>
    </el-table-column>

    <el-table-column :label="$t('address')" min-width="200">
      <template slot-scope="scope">
        <a
          @click.prevent="toLink(scope.row.spec.ingress.ingressAddresses[0])"
          v-if="scope.row.spec.ingress.ingressAddresses"
          target="_blank"
        >
          {{ scope.row.spec.ingress.ingressAddresses[0] }}
        </a>

        <span v-else>-</span>
      </template>
    </el-table-column>

    <el-table-column :label="$t('ingressRules')" width="300">
      <template slot-scope="scope">
        <div v-if="scope.row.spec.ingress.rules">
          <div v-for="item in scope.row.spec.ingress.rules" :key="item.host">
            <div class="ingress-name">
              <div class="title">
                {{ item.host }}

                <span class="tls">(TLS: {{ item.tls ? "ON" : "OFF" }})</span>
              </div>
            </div>
          </div>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('Cluster')">
      <template slot-scope="scope">
        {{ JSON.parse(scope.row.metadata.annotations.providerTopology).cluster }}
      </template>
    </el-table-column>

    <el-table-column :label="$t('handle.handle')" width="100">
      <template slot-scope="scope">
        <el-dropdown trigger="click">
          <button class="table-row-handle">
            <i class="el-icon-more-outline"></i>
          </button>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <delete
                :vendor="JSON.parse(scope.row.metadata.annotations.providerTopology).vendor"
                :region="JSON.parse(scope.row.metadata.annotations.providerTopology).region"
                :cluster="JSON.parse(scope.row.metadata.annotations.providerTopology).cluster"
                :name="scope.row.metadata.name"
                :namespace="scope.row.metadata.namespace"
                :type="type"
                v-on="$listeners"
              >
                {{ $t("handle.delete") }}
              </delete>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import moment from "moment";
import Delete from "@/pages/Delete/index";
export default {
  props: {
    data: Array,
    toDetail: Function
  },

  components: {
    Delete
  },

  methods: {
    moment,

    toLink(href) {
      window.open(`http://${href}`, "_blank");
    }
  },

  computed: {
    type() {
      return this.$route.params.type;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
.ingress-name {
  .title {
    @include title(12px);
  }

  .tls {
    font-size: 12px;
    color: $color-sub;
  }
}
</style>
