<template>
  <div v-loading="loading">
    <div v-if="data && data.length > 0">
      <application
        :data="data"
        :toDetail="toDetail"
        v-if="['Deployment', 'StatefulSet', 'CronJob', 'DaemonSet'].includes(type)"
        @update="init"
        @delete="afterDelete"
      />

      <service :data="data" :toDetail="toDetail" v-if="['Service'].includes(type)" @delete="afterDelete" />
      <ingress :data="data" :toDetail="toDetail" v-if="['Ingress'].includes(type)" @delete="afterDelete" />
      <config-map
        :data="data"
        :toDetail="toDetail"
        v-if="['ConfigMap', 'Secret'].includes(type)"
        @delete="afterDelete"
      />
      <persistent-volume
        :data="data"
        :toDetail="toDetail"
        v-if="['PersistentVolume'].includes(type)"
        @delete="afterDelete"
      />
      <persistent-volume-claims
        :data="data"
        :toDetail="toDetail"
        v-if="['PersistentVolumeClaims'].includes(type)"
        @delete="afterDelete"
      />
      <namespace :data="data" :toDetail="toDetail" v-if="['Namespace'].includes(type)" @delete="afterDelete" />
      <cluster :data="data" :toDetail="toDetail" v-if="['Cluster'].includes(type)" />
      <storage-class :data="data" v-if="['StorageClass'].includes(type)" @delete="afterDelete" />

      <el-pagination
        layout=" prev, pager, next, sizes, total"
        :total="total"
        :page-size="currentPageSize"
        :page-sizes="[10, 15, 20, 30, 50]"
        :current-page="currentPage"
        @current-change="currentChange"
        @size-change="sizeChange"
      >
      </el-pagination>
    </div>

    <result type="empty" v-else />
  </div>
</template>

<script>
import { applicationList } from "api/deployment";
import { serviceList } from "api/service";
import { ingressList } from "api/ingress";
import { namespaceList } from "api/namespace";
import { configMapList } from "api/configmap";
import { clusterList } from "api/cluster";
import { secretList } from "api/secret";
import { pvcList, pvList } from "api/pv";
import { storageClassList } from "api/storageClass";

import moment from "moment";
import Result from "@/components/Result.vue";

import Application from "./Application";
import Service from "./Service";
import Ingress from "./Ingress";
import PersistentVolume from "./PersistentVolume";
import PersistentVolumeClaims from "./PersistentVolumeClaims";
import ConfigMap from "./ConfigMap";
import Namespace from "./Namespace";
import Cluster from "./Cluster";
import StorageClass from "./StorageClass";

export default {
  components: {
    Result,
    Application,
    Service,
    Ingress,
    PersistentVolume,
    PersistentVolumeClaims,
    ConfigMap,
    Namespace,
    Cluster,
    StorageClass
  },

  props: {
    search: Array
  },

  watch: {
    search: {
      handler(newVal) {
        console.log(newVal);

        if (newVal) {
          let labels = [];
          let metadatas = [];
          let locations = [];

          newVal.forEach(item => {
            item = item.replace(":", "=");

            let key = item.split("=")[0];

            if (key === "name" || key === "namespace") {
              metadatas.push(item);
            } else if (key === "providers" || key === "regions" || key === "clusters") {
              locations.push(item);
            } else {
              labels.push(item);
            }
          });

          this.searchQuery = {
            labels: labels.join(","),
            metadatas: metadatas.join(","),
            locations: locations.join(",")
          };

          this.getList(1, this.currentPageSize, {
            type: this.type,
            ...this.searchQuery
          });
        }
      }
    }
  },

  data() {
    return {
      data: [],
      total: undefined,
      loading: false,
      currentPage: 1,
      currentPageSize: 10,
      searchQuery: {}
    };
  },

  computed: {
    type() {
      return this.$route.params.type;
    }
  },

  methods: {
    getList(page = 1, pageSize = this.currentPageSize, search = { type: this.type, ...this.searchQuery }) {
      this.loading = true;
      this.currentPage = page;

      let action = null;

      switch (this.type) {
        case "Service":
          action = serviceList;
          break;

        case "Ingress":
          action = ingressList;
          break;

        case "Namespace":
          action = namespaceList;
          break;

        case "Cluster":
          action = clusterList;
          break;

        case "ConfigMap":
          action = configMapList;
          break;

        case "Secret":
          action = secretList;
          break;

        case "PersistentVolumeClaims":
          action = pvcList;
          break;

        case "PersistentVolume":
          action = pvList;
          break;

        case "StorageClass":
          action = storageClassList;
          break;

        default:
          action = applicationList;
      }

      action({ page, pageSize, ...search }).then(response => {
        this.loading = false;
        if (response.code === 0) {
          this.total = response.data.total;
          this.data = response.data.items;
        }
      });
    },

    init() {
      this.getList(this.currentPage, this.currentPageSize);
    },

    afterDelete() {
      this.getList(this.currentPage, this.currentPageSize);
    },

    toDetail(row) {
      let { name, namespace } = row.metadata;
      let { vendor, region, cluster } = JSON.parse(row.metadata.annotations.providerTopology);

      let query = namespace
        ? { vendor: vendor, region: region, cluster: cluster, namespace: namespace }
        : { vendor: vendor, region: region, cluster: cluster };

      this.$router.push({
        path: `/detail/${this.type}/${name}`,
        query
      });
    },

    moment,

    currentChange(page) {
      this.currentPage = page;
      this.getList(page);
    },

    sizeChange(size) {
      this.currentPageSize = size;
      this.getList(1, size);
    }
  }

  // mounted() {
  //   this.getList();
  // }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.type-list {
  @include resource-card();
}
</style>
