<template>
  <el-table stripe :data="data">
    <el-table-column type="expand">
      <template slot-scope="scope">
        <node-list
          :vendor="JSON.parse(scope.row.metadata.annotations.providerTopology).vendor"
          :region="JSON.parse(scope.row.metadata.annotations.providerTopology).region"
          :cluster="scope.row.metadata.name"
        />
      </template>
    </el-table-column>
    <el-table-column :label="$t('name')" prop="metadata.name" sortable width="200">
      <template slot-scope="scope">
        <div class="table-name-info">
          <svg-icon :icon-class="$route.params.type"></svg-icon>
          <div class="content">
            <a @click="toDetail(scope.row)">
              {{ scope.row.metadata.name }}
            </a>

            <span class="desc">
              {{ moment(scope.row.spec.age).fromNow() }}
            </span>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column :label="$t('Namespace')">
      <template slot-scope="scope">
        <div v-if="'namespaces' in scope.row.spec && scope.row.spec.namespaces.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.namespaces" :key="item">
                <a @click.prevent="toResource(scope.row, item, 'Namespace')">{{ item }}</a>
              </div>
            </div>

            <a href="javascript:;">{{ scope.row.spec.namespaces.length }}</a>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('Deployment')">
      <template slot-scope="scope">
        <div v-if="'deployments' in scope.row.spec && scope.row.spec.deployments.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.deployments" :key="item">
                <a>{{ item }}</a>
              </div>
            </div>

            <div class="title">{{ scope.row.spec.deployments.length }}</div>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('StatefulSet')">
      <template slot-scope="scope">
        <div v-if="'statefulsets' in scope.row.spec && scope.row.spec.statefulsets.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.statefulsets" :key="item">
                <a>{{ item }}</a>
              </div>
            </div>

            <div class="title">{{ scope.row.spec.statefulsets.length }}</div>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('CronJob')">
      <template slot-scope="scope">
        <div v-if="'cronJobs' in scope.row.spec && scope.row.spec.cronJobs.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.cronJobs" :key="item">
                <a>{{ item }}</a>
              </div>
            </div>

            <div class="title">{{ scope.row.spec.cronJobs.length }}</div>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('DaemonSet')">
      <template slot-scope="scope">
        <div v-if="'daemonSets' in scope.row.spec && scope.row.spec.daemonSets.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.daemonSets" :key="item">
                <a>{{ item }}</a>
              </div>
            </div>

            <div class="title">{{ scope.row.spec.daemonSets.length }}</div>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('Service')">
      <template slot-scope="scope">
        <div v-if="'services' in scope.row.spec && scope.row.spec.services.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.services" :key="item">
                <a>{{ item }}</a>
              </div>
            </div>

            <div class="title">{{ scope.row.spec.services.length }}</div>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('Ingress')">
      <template slot-scope="scope">
        <div v-if="'ingresses' in scope.row.spec && scope.row.spec.ingresses.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.ingresses" :key="item">
                <a>{{ item }}</a>
              </div>
            </div>

            <div class="title">{{ scope.row.spec.ingresses.length }}</div>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('ConfigMap')">
      <template slot-scope="scope">
        <div v-if="'configMaps' in scope.row.spec && scope.row.spec.configMaps.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.configMaps" :key="item">
                <a>{{ item }}</a>
              </div>
            </div>

            <div class="title">{{ scope.row.spec.configMaps.length }}</div>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('Secret')">
      <template slot-scope="scope">
        <div v-if="'secrets' in scope.row.spec && scope.row.spec.secrets.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.secrets" :key="item">
                <a>{{ item }}</a>
              </div>
            </div>

            <div class="title">{{ scope.row.spec.secrets.length }}</div>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('PersistentVolumeClaims')">
      <template slot-scope="scope">
        <div v-if="'persistentVolumeClaims' in scope.row.spec && scope.row.spec.persistentVolumeClaims.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.persistentVolumeClaims" :key="item">
                <a>{{ item }}</a>
              </div>
            </div>

            <div class="title">{{ scope.row.spec.persistentVolumeClaims.length }}</div>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('region')" width="120" show-overflow-tooltip>
      <template slot-scope="scope">
        {{ JSON.parse(scope.row.metadata.annotations.providerTopology).region }}
      </template>
    </el-table-column>

    <el-table-column :label="$t('vendor')" width="120" show-overflow-tooltip>
      <template slot-scope="scope">
        {{ JSON.parse(scope.row.metadata.annotations.providerTopology).vendor }}
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import moment from "moment";
import NodeList from "@/pages/Detail/components/NodeList";

export default {
  components: {
    NodeList
  },

  props: {
    data: Array,
    toDetail: Function
  },

  methods: {
    moment,

    toResource(row, name, type) {
      let { vendor, region, cluster } = JSON.parse(row.metadata.annotations.providerTopology);

      let query = { vendor, region, cluster, namespace: name };

      this.$router.push({
        path: `/detail/${type}/${name}`,
        query
      });
    }
  },

  computed: {
    type() {
      return this.$route.params.type;
    }
  }
};
</script>
