<template>
  <el-table stripe :data="data">
    <el-table-column :label="$t('name')" prop="metadata.name" sortable width="200">
      <template slot-scope="scope">
        <div class="table-name-info">
          <svg-icon :icon-class="$route.params.type"></svg-icon>
          <div class="content">
            <a @click="toDetail(scope.row)">
              {{ scope.row.metadata.name }}
            </a>

            <span class="desc">
              {{ moment(scope.row.spec.age).fromNow() }}
            </span>
          </div>
        </div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('Cluster')" width="300">
      <template slot-scope="scope">
        <div class="title">
          {{ JSON.parse(scope.row.metadata.annotations.providerTopology).cluster }}
        </div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('status')">
      <template slot-scope="scope">
        <div class="status success" v-if="scope.row.status.namespaceStatus.phase == 'Active'">Active</div>
        <div class="status danger" v-else>{{ scope.row.status.namespaceStatus.phase }}</div>
      </template>
    </el-table-column>

    <el-table-column label="resourceQuota" width="180">
      <template slot-scope="scope">
        <div class="title">
          <span>{{ $t("cpu") }}：</span>
          {{ "hard" in scope.row.spec.resourceQuota ? scope.row.spec.resourceQuota.hard.cpu : "-" }}
        </div>

        <div class="title">
          <span>{{ $t("memory") }}：</span>
          {{ "hard" in scope.row.spec.resourceQuota ? scope.row.spec.resourceQuota.hard.memory : "-" }}
        </div>
      </template>
    </el-table-column>

    <el-table-column label="limitRange" width="180">
      <template slot-scope="scope">
        <div v-if="scope.row.spec.limitRange.limits">
          <div v-for="item in scope.row.spec.limitRange.limits" :key="item.type">
            <div class="title">{{ item.type }}</div>
          </div>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('Deployment')">
      <template slot-scope="scope">
        <div v-if="'deployments' in scope.row.spec && scope.row.spec.deployments.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.deployments" :key="item">
                <a @click.prevent="toResource(scope.row, item, 'Deployment')">{{ item }}</a>
              </div>
            </div>

            <a href="javascripy:;">{{ scope.row.spec.deployments.length }}</a>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('StatefulSet')">
      <template slot-scope="scope">
        <div v-if="'statefulsets' in scope.row.spec && scope.row.spec.statefulsets.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.statefulsets" :key="item">
                <a @click.prevent="toResource(scope.row, item, 'StatefulSet')">{{ item }}</a>
              </div>
            </div>

            <a href="javascripy:;">{{ scope.row.spec.statefulsets.length }}</a>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('CronJob')">
      <template slot-scope="scope">
        <div v-if="'cronJobs' in scope.row.spec && scope.row.spec.cronJobs.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.cronJobs" :key="item">
                <a @click.prevent="toResource(scope.row, item, 'CronJob')">{{ item }}</a>
              </div>
            </div>

            <a href="javascripy:;">{{ scope.row.spec.cronJobs.length }}</a>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('DaemonSet')">
      <template slot-scope="scope">
        <div v-if="'daemonSets' in scope.row.spec && scope.row.spec.daemonSets.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.daemonSets" :key="item">
                <a @click.prevent="toResource(scope.row, item, 'DaemonSet')">{{ item }}</a>
              </div>
            </div>

            <a href="javascripy:;">{{ scope.row.spec.daemonSets.length }}</a>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('Service')">
      <template slot-scope="scope">
        <div v-if="'services' in scope.row.spec && scope.row.spec.services.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.services" :key="item">
                <a @click.prevent="toResource(scope.row, item, 'Service')">{{ item }}</a>
              </div>
            </div>

            <a href="javascripy:;">{{ scope.row.spec.services.length }}</a>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('ConfigMap')">
      <template slot-scope="scope">
        <div v-if="'configMaps' in scope.row.spec && scope.row.spec.configMaps.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.configMaps" :key="item">
                <a @click.prevent="toResource(scope.row, item, 'ConfigMap')">{{ item }}</a>
              </div>
            </div>

            <a href="javascripy:;">{{ scope.row.spec.configMaps.length }}</a>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('Secret')">
      <template slot-scope="scope">
        <div v-if="'secrets' in scope.row.spec && scope.row.spec.secrets.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.secrets" :key="item">
                <a @click.prevent="toResource(scope.row, item, 'Secret')">{{ item }}</a>
              </div>
            </div>

            <a href="javascripy:;">{{ scope.row.spec.secrets.length }}</a>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('PersistentVolumeClaims')">
      <template slot-scope="scope">
        <div v-if="'persistentVolumeClaims' in scope.row.spec && scope.row.spec.persistentVolumeClaims.length > 0">
          <el-tooltip placement="right" popper-class="all-list-popper">
            <div slot="content">
              <div v-for="item in scope.row.spec.persistentVolumeClaims" :key="item">
                <a @click.prevent="toResource(scope.row, item, 'PersistentVolumeClaims')">{{ item }}</a>
              </div>
            </div>

            <a href="javascripy:;">{{ scope.row.spec.persistentVolumeClaims.length }}</a>
          </el-tooltip>
        </div>

        <div v-else>-</div>
      </template>
    </el-table-column>

    <el-table-column :label="$t('handle.handle')" width="100">
      <template slot-scope="scope">
        <el-dropdown trigger="click">
          <button class="table-row-handle">
            <i class="el-icon-more-outline"></i>
          </button>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <delete
                :vendor="JSON.parse(scope.row.metadata.annotations.providerTopology).vendor"
                :region="JSON.parse(scope.row.metadata.annotations.providerTopology).region"
                :cluster="JSON.parse(scope.row.metadata.annotations.providerTopology).cluster"
                :name="scope.row.metadata.name"
                :type="type"
                v-on="$listeners"
              >
                {{ $t("handle.delete") }}
              </delete>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import moment from "moment";
import Delete from "@/pages/Delete/index";
export default {
  props: {
    data: Array,
    toDetail: Function
  },

  components: {
    Delete
  },

  methods: {
    moment,

    toResource(row, name, type) {
      let namespace = row.metadata.name;
      let { vendor, region, cluster } = JSON.parse(row.metadata.annotations.providerTopology);

      let query = { vendor, region, cluster, namespace };

      this.$router.push({
        path: `/detail/${type}/${name}`,
        query
      });
    }
  },

  computed: {
    type() {
      return this.$route.params.type;
    }
  }
};
</script>

<style lang="scss">
.all-list-popper {
  & > div {
    max-height: 400px;
    overflow-y: scroll;

    a {
      color: #fff;
      display: block;
      padding: 4px;
      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
}
</style>
