import http from "../http";

export const applicationList = params => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/applications`
  });
};

export const applicationListByNamespace = (payload, params) => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/namespaces/${payload.namespace}/applications`
  });
};

export const applicationSubmit = (data, headers) => {
  return http({
    method: "post",
    url: `/crd.kubestar.io/namespaces/${data.metadata.namespace}/applications`,
    data,
    headers
  });
};

export const applicationUpdate = (data, headers) => {
  return http({
    method: "put",
    url: `/crd.kubestar.io/namespaces/${data.metadata.namespace}/applications`,
    data,
    headers
  });
};

export const applicationHpa = (data, hpaData) => {
  return http({
    method: "patch",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/hpas/${hpaData.metadata.name}`,
    data: hpaData
  });
};

export const deleteHpa = (data, hpaName) => {
  return http({
    method: "delete",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/hpas/${hpaName}`
  });
};

export const deploymentDetail = (data, params) => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/applications/${data.application}`
  });
};

export const applicationTopo = (data, params) => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/namespaces/${data.namespace}/applications/${data.application}/topology`
  });
};

export const podList = (data, params) => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/applications/${data.application}/pods`
  });
};

export const applicationDelete = (data, params) => {
  return http({
    method: "delete",
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/applications/${data.name}`,
    params
  });
};

export const rollbackList = data => {
  return http({
    method: "get",
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/deployments/${data.deployment}/replicasets`
  });
};

export const podRestart = data => {
  return http({
    method: "delete",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/pods/${data.pod}`
  });
};

export const cronJobPause = data => {
  return http({
    method: "put",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/cronjobs/${data.name}/${data.pause}`
  });
};

export const applicationEvent = (data, params) => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/applications/${data.application}/events`
  });
};

export const applicationBatchHandle = (data, params) => {
  return http({
    method: "post",
    params,
    data,
    url: `/crd.kubestar.io/applications`
  });
};

export const importYAML = (data, headers) => {
  return http({
    method: "post",
    url: `/crd.kubestar.io/manifests`,
    data,
    headers
  });
};
