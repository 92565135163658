<template>
  <div>
    <div class="list-title">
      {{ $t(type) }}
    </div>

    <div class="search-container">
      <div class="search-input-container">
        <i class="el-icon-search"></i>

        <el-tag
          :key="label"
          v-for="(label, index) in searchText"
          closable
          :disable-transitions="false"
          size="small"
          @close="labelClose(index)"
        >
          {{ formatLabel(label) }}
        </el-tag>

        <el-popover class="popover-container" ref="popover" placement="bottom-start" trigger="manual" v-model="popover">
          <div v-if="query && includes(query, ':')" class="popover-value-container">
            <ul class="label-list" ref="list">
              <li v-if="valLoading">Loading...</li>

              <div v-else>
                <li
                  v-for="(item, index) in valOptions"
                  :key="item"
                  @click="selectVal(item)"
                  @mouseenter.self="pointerSet(index)"
                  :class="{ selected: getHighLight(index) }"
                >
                  {{ item }}
                </li>

                <li class="disabled" v-if="valOptions.length < 1">
                  {{ $t("empty") }}
                </li>
              </div>
            </ul>

            <div v-if="continueBtn" class="continue-button">
              <a @click="getMoreValues">{{ $t("handle.moreConf") }}</a>
            </div>
          </div>

          <div v-else>
            <ul class="label-list">
              <li class="label-key">{{ $t("searchLabelKey") }}</li>

              <li
                v-for="(item, index) in keyOptions"
                :key="item.value"
                @click="selectKey(item)"
                @mouseenter.self="pointerSet(index)"
                :class="{ selected: getHighLight(index), disabled: searchTextKeys.indexOf(item.value) > -1 }"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>

          <div slot="reference">
            <input
              v-model="query"
              ref="query"
              @focus="queryFocus"
              @blur="popover = false"
              @keydown.down.prevent="pointerForward()"
              @keydown.up.prevent="pointerBackward()"
              @keypress.enter.prevent.stop.self="pointerEnter()"
            />
          </div>
        </el-popover>
      </div>

      <el-tooltip effect="dark" :content="$t('handle.refresh')" placement="bottom-start">
        <el-button plain icon="el-icon-refresh" size="small" @click="refresh"></el-button>
      </el-tooltip>

      <el-button size="small" type="primary" @click="$router.push(`/add/${type}`)" v-if="!['Cluster'].includes(type)">
        {{ $t("handle.add") }}
      </el-button>
    </div>

    <div class="record-container">
      <span class="title">🔍 搜索历史:</span>
      <span class="desc" v-if="historyRecords.length < 1">-</span>

      <div class="record-items" v-for="item in historyRecords" :key="item">
        <el-tag type="primary" effect="plain" size="small" @click="recordClick(item)">
          {{ formatLabel(item) }}
        </el-tag>
      </div>
    </div>

    <list :search="searchText" ref="allList" />
  </div>
</template>

<script>
import List from "./List";
import { labelList } from "api/label";
import { includes, isString } from "lodash";

import i18n from "@/lang";

export default {
  components: { List },
  data() {
    return {
      options: [],
      searchText: [],
      query: "",
      queryBackup: "",

      popover: false,
      loading: false,

      locationList: [],
      namespace: "",

      allKeyOptions: [
        {
          label: i18n.t("name"),
          value: "name"
        },
        {
          label: i18n.t("Namespace"),
          value: "namespace"
        },
        {
          label: i18n.t("Cluster"),
          value: "clusters"
        },

        {
          label: i18n.t("region"),
          value: "regions"
        },

        {
          label: i18n.t("vendor"),
          value: "providers"
        }
      ],

      valOptions: [],

      keyOptions: [
        {
          label: i18n.t("name"),
          value: "name"
        },
        {
          label: i18n.t("Namespace"),
          value: "namespace"
        },
        {
          label: i18n.t("Cluster"),
          value: "clusters"
        },

        {
          label: i18n.t("region"),
          value: "regions"
        },

        {
          label: i18n.t("vendor"),
          value: "providers"
        }
      ],

      namespaceList: [],
      valLoading: true,

      valCurrentPage: 1,
      continueBtn: false,
      pointer: 0,
      historyRecords: []
    };
  },

  watch: {
    query: function(query) {
      this.watchQuery(query);
    }
  },

  methods: {
    async watchQuery(query) {
      this.pointerReset();

      if (query) {
        if (!includes(query, ":")) {
          // query中没有: 则搜索 allKeyOptions
          this.keyOptions = this.allKeyOptions.filter(item => {
            return includes(item.label, query);
          });

          // 如果过滤的keyOptions长度等于0，则代表用户希望键入自己定义的key: val
          if (this.keyOptions.length > 0) {
            this.popover = true;
          } else {
            this.popover = false;
            this.queryBackup = this.query;
          }
        } else {
          if (this.popover) {
            // 当搜索的val发生变化，重置当前页码
            this.valCurrentPage = 1;
            this.continueBtn = false;

            this.valLoading = true;
            this.valOptions = await this.getValues();
            this.valLoading = false;
          }
        }
      } else {
        this.popover = true;
        this.keyOptions = this.allKeyOptions;
      }
    },

    queryFocus() {
      if (this.query) {
        this.keyOptions.length > 0 ? (this.popover = true) : (this.popover = false);
      } else {
        this.popover = true;
      }
    },

    getHighLight(index) {
      if (index === this.pointer) return true;
      return false;
    },

    pointerSet(index) {
      this.pointer = index;
    },

    pointerForward() {
      if (this.pointer < this.filterOptions.length - 1) this.pointer++;

      if (this.$refs.list) {
        if (this.$refs.list.scrollTop <= (this.pointer + 1) * 30 - 500)
          this.$refs.list.scrollTop = (this.pointer + 1) * 30 - 500;
      }
    },

    pointerBackward() {
      if (this.pointer > 0) this.pointer--;

      if (this.$refs.list) {
        if (this.$refs.list.scrollTop >= this.pointer * 30) this.$refs.list.scrollTop = this.pointer * 30;
      }
    },

    pointerReset() {
      this.pointer = 0;
      if (this.$refs.list) this.$refs.list.scrollTop = 0;
    },

    labelClose(index) {
      this.popover = false;
      this.searchText.splice(index, 1);
      this.searchHistory();
    },

    selectKey(item) {
      if (this.searchTextKeys.indexOf(item.value) == -1) {
        this.queryBackup = item.value;
        this.query = `${item.label}:`;
        this.$refs["query"].focus();
      }
    },

    selectVal(item) {
      this.searchText.push(`${this.queryBackup}:${item}`);
      this.searchHistory();
      this.searchRecords(`${this.queryBackup}:${item}`);
      this.resetQuery();
    },

    getSearchHistoryList() {
      let type = this.type;
      let list = [];
      if (localStorage.searchHistory && isString(localStorage.searchHistory)) {
        let searchHistory = JSON.parse(localStorage.searchHistory);
        if (searchHistory[type]) list = searchHistory[type];
      }
      this.searchText = list;
    },

    getSearchRecords() {
      let type = this.type;

      if (localStorage.searchRecords && isString(localStorage.searchRecords)) {
        let searchRecords = JSON.parse(localStorage.searchRecords);
        this.historyRecords = searchRecords[type] || [];
      }
    },

    searchRecords(val) {
      let type = this.type;

      let searchRecords =
        localStorage.searchRecords && isString(localStorage.searchRecords)
          ? JSON.parse(localStorage.searchRecords)
          : {};

      if (this.historyRecords.length > 5) this.historyRecords.pop();

      if (this.historyRecords.indexOf(val) > -1) {
        this.historyRecords.forEach((item, index) => {
          if (item == val) this.historyRecords.splice(index, 1);
        });
      }
      this.historyRecords.unshift(val);

      searchRecords[type] = this.historyRecords;
      localStorage.searchRecords = JSON.stringify(searchRecords);
    },

    searchHistory() {
      let type = this.type;
      let searchHistory =
        localStorage.searchHistory && isString(localStorage.searchHistory)
          ? JSON.parse(localStorage.searchHistory)
          : {};

      searchHistory[type] = this.searchText;
      localStorage.searchHistory = JSON.stringify(searchHistory);
    },

    recordClick(val) {
      let valKey = val.split(":")[0];

      if (this.searchText.indexOf(val) == -1) {
        if (this.searchText.length < 1) {
          this.searchText.push(val);
        } else {
          this.searchText.forEach((item, index) => {
            let itemKey = item.split(":")[0];
            if (valKey == itemKey) {
              this.searchText.splice(index, 1);
            }
          });

          this.searchText.push(val);
        }

        this.searchHistory();
      }
    },

    formatLabel(label) {
      let key = label.split(":")[0];
      let value = label.split(":")[1];
      let i18nKey = key;
      switch (key) {
        case "name":
          i18nKey = this.$t("name");
          break;

        case "namespace":
          i18nKey = this.$t("Namespace");
          break;

        case "clusters":
          i18nKey = this.$t("Cluster");
          break;

        case "regions":
          i18nKey = this.$t("region");
          break;

        case "providers":
          i18nKey = this.$t("vendor");
          break;
      }

      return `${i18nKey}:${value}`;
    },

    pointerEnter() {
      if (this.popover) {
        if (!includes(this.query, ":")) {
          this.selectKey(this.keyOptions[this.pointer]);
        } else {
          let value = this.valOptions[this.pointer] || this.query.split(":")[1].trim();
          this.selectVal(value);
        }
      } else {
        if (includes(this.query, ":")) {
          let key = this.query.split(":")[0].trim();
          let value = this.query.split(":")[1].trim();
          if (key && value) {
            this.searchText.push(`${key}:${value}`);
            this.resetQuery();
          }
        } else {
          if (this.query) {
            this.searchText.push(`name:${this.query}`);
            this.resetQuery();
          }
        }
      }
    },

    resetQuery() {
      this.query = "";
      this.queryBackup = "";

      this.$nextTick(() => {
        this.popover = false;
        this.$refs["query"].blur();
      });
    },

    async getValues() {
      let result = [];
      let value = this.query.split(":")[1] || "";
      let paramString = this.queryBackup + "=" + value;
      let response = await labelList({ labels: paramString, type: this.type, pageSize: 50, page: this.valCurrentPage });
      if (response.code === 0) {
        result = response.data.items.map(item => item.value);
        this.continueBtn = response.data.continue;
      }

      return result;
    },

    async getMoreValues() {
      this.valCurrentPage += 1;
      this.popover = true;

      let moreValues = await this.getValues();
      this.$refs["query"].focus();

      this.valOptions.push(...moreValues);
    },

    toDetail(item) {
      let { region, vendor, cluster, namespace, type, name } = item;

      let query = namespace
        ? { vendor: vendor, region: region, cluster: cluster, namespace: namespace }
        : { vendor: vendor, region: region, cluster: cluster };

      this.$router.push({
        path: `/detail/${type}/${name}`,
        query
      });
    },

    create() {
      this.$router.push(`/add/${this.type}`);
    },

    includes,

    refresh() {
      this.$nextTick(() => {
        if (!this.$refs["allList"].loading)
          this.$refs["allList"].getList(this.$refs["allList"].currentPage, this.$refs["allList"].currentPageSize);
      });
    }
  },

  computed: {
    type() {
      return this.$route.params.type;
    },

    filterOptions() {
      let options = [];
      includes(this.query, ":") ? (options = this.valOptions) : (options = this.keyOptions);
      return options;
    },

    searchTextKeys() {
      return this.searchText.map(item => {
        return item.split(":")[0];
      });
    }
  },

  mounted() {
    this.getSearchHistoryList();
    this.getSearchRecords();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.search-container {
  background-color: #f9fbfd;
  padding: 10px;
  @include flex();
}

.search-input-container {
  flex: 1;
  margin-right: 10px;
  background: #fff;
  border: 1px solid $border-color;
  border-radius: 18px;
  @include flex(flex-start);
  padding: 1px 20px;

  & > .el-icon-search {
    margin-right: 10px;
    color: $color-sub;
  }

  & > .popover-container {
    flex-grow: 1;
    border: none;
    line-height: 28px;

    .el-popover__reference {
      & > input {
        border: none;
        line-height: 28px;
        width: 100%;
        display: block;
      }
    }
  }

  .el-tag {
    margin-right: 8px;
  }
}

.popover-value-container {
  .continue-button {
    @include title(12px);
    margin-top: 10px;
  }
}

.label-list {
  max-height: 500px;
  overflow-y: scroll;

  li {
    padding: 7px;
    cursor: pointer;
    text-indent: 10px;

    &.selected {
      background-color: $card-bg-color;
    }

    // &.history-list-item:hover {
    //   background-color: $card-bg-color;
    // }

    &.disabled {
      color: #bcbec2;
      // background-color: #f4f4f5;
      // border-color: #e9e9eb;
      cursor: not-allowed;
    }

    &.label-key {
      color: $color-sub;
      text-indent: 0;
    }

    @include title(12px);
  }
}

.search-referral-tips {
  position: relative;
  height: 80px;
  color: $color-main;
  font-size: 12px;
  margin-bottom: 2 * $grid-height;
  padding-left: 80px;

  img {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    display: inline-block;
    margin-top: 20px;
  }
}

.search-referral-list {
  @include flex(flex-start, center, nowrap);
  margin-bottom: 10px;
  padding: 6px;

  & > .icon {
    margin-right: 2 * $grid-height;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  & > .content {
    flex: 1;
    overflow: hidden;

    & > a {
      @include router-link();
      @include text-overflow();
      font-size: 14px;
      font-weight: 600;
    }

    .desc {
      font-size: $normal-font-size;
      color: $color-sub;
      @include text-overflow();
    }
  }
}

.record-container {
  padding: 10px;

  .record-items {
    display: inline-block;
    padding: 0 4px;

    .el-tag {
      cursor: pointer;

      &.el-tag--effect {
        cursor: not-allowed;
      }
    }
  }
}
</style>
