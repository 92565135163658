import http from "../http";

export const storageClassSubmit = (data, headers) => {
  return http({
    method: "post",
    url: `/k8s.kubestar.io/storageclasses`,
    data,
    headers
  });
};

export const storageClassList = (params) => {
  return http({
    method: "get",
    url: `/crd.kubestar.io/storageclasses`,
    params
  });
};

export const storageClassListByLocation = data => {
  return http({
    method: "get",
    url: `/crd.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/storageclasses`
  });
};

export const storageClassDelete = data => {
  return http({
    method: "delete",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/storageclasses/${data.name}`
  });
};
