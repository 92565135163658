import http from "../http";

export const serviceDelete = data => {
  return http({
    method: "delete",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/services/${data.name}`
  });
};

export const serviceDetail = (data, headers) => {
  return http({
    method: "get",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/services/${data.service}`,
    headers
  });
};

export const serviceList = params => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/services`
  });
};

export const serviceSubmit = (data, headers) => {
  return http({
    method: "post",
    data,
    url: `/k8s.kubestar.io/namespaces/${data.metadata.namespace}/services`,
    headers
  });
};
